import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, combineLatest, lastValueFrom, map, Observable, of, tap } from 'rxjs';

import { ENVIRONMENT, Environment } from '@environment';
import { AuthService } from '@usheru-hff/authentication/domain';
import { UsheruApi } from '@usheru-hff/shared/data-access-backend';
import {
  ChannelSettings,
  Cinema,
  CinemaChannel,
  ConsumerLead,
  Country,
  Festival,
  FestivalType,
  Language,
  Movie,
  MovieFestivalListWrapper,
  MovieListWrapper,
  MovieStreaming,
  MovieStreamingListWrapper,
  NearCity,
  NearestCitiesFilter,
  PageType,
  Performance,
  PerformanceGroup,
  PerformanceListWrapper,
  posGetMovieFromApi,
  RightAvailabilitiesFiltersWrapper,
  RightAvailability,
  Star,
  StarListWrapper,
  StreamingListWrapper,
  StreamingPlatform,
  Tag,
  TagType,
  UserRole
} from '@usheru-hff/shared/domain';
import { ChannelService } from '@usheru-hff/shared/utils-channel';
import { TranslationsService } from '@usheru-hff/shared/utils-translation';

@Injectable({
  providedIn: 'root'
})
export class MovieService {
  movie$ = new BehaviorSubject<Movie | null>(null);

  private moviesLastType: string = '';

  private moviesLastPage = 1;

  private streamingPlatformsMapping: { [key: string]: StreamingPlatform[] } = {};

  updateMovie(movie: Movie) {
    if (movie.id === this.movie$.value?.id && Object.keys(movie).length <= Object.keys(this.movie$).length) return;
    this.movie$.next(movie);
    if (typeof movie.settings === 'string') {
      //!NOTE In case of this error review what is the source of the movie
      //!NOTE this movie must pass the **posGetMovieFromApi** method
      console.error('Movie setting bad format');
    }
  }

  get placeholderData() {
    return this.env?.placeholderData;
  }

  get isLocalEnv() {
    return this.env.mode == 'develop';
  }

  userSig = toSignal(this.authService.$userStatus);

  constructor(
    private usheruApi: UsheruApi,
    private http: HttpClient,
    private channelService: ChannelService,
    private translationsService: TranslationsService,
    @Inject(ENVIRONMENT) private env: Environment,
    @Optional() private authService: AuthService
  ) {}

  getMoviesCompact(keyword: string, trLanguage: string): Observable<Movie[]> {
    const headers = this.usheruApi.getStandardHeader();
    const params = { keyword, trLanguage };
    return this.http
      .get<Movie[]>(`${this.env.api?.url}/movies/search/`, { headers, params })
      .pipe(map(vals => vals.map(val => posGetMovieFromApi(val))));
  }

  getMovie(idSlug: string | number, trLanguage?: string, countrySlug?: string): Observable<Movie> {
    // PLACEHOLDER /////
    if (this.isLocalEnv && this.placeholderData?.movie) {
      const placeholder = this.placeholderData.movie;
      console.warn('Using placeholder data for movie');
      return this.http
        .get(`https://cdnstatic.usheru.com/assets/placeholder-data/movie-${placeholder}.json`, {
          headers: this.usheruApi.getStandardHeader()
        })
        .pipe(map(vals => posGetMovieFromApi(vals as Movie)));
    }

    ////////////////////

    let params = new HttpParams();
    if (trLanguage) params = params.set('trLanguage', trLanguage);
    if (countrySlug) params = params.set('country', countrySlug);
    const headers = this.getMovieHeaders();
    return this.http
      .get<Movie>(`${this.env.api?.url}/movies/${idSlug}`, {
        headers,
        params
      })
      .pipe(
        map(vals => posGetMovieFromApi(vals as Movie)),
        tap(movie => this.updateMovie(movie))
      );
  }

  getMovieHeaders() {
    if (this.isCommunityEnabled && this.authService) {
      const token = this.authService.accessToken();
      return this.authService.isLoggedIn()
        ? this.usheruApi.generateBearerHeader(token)
        : this.usheruApi.getStandardHeader();
    }
    return this.usheruApi.getStandardHeader();
  }

  get isCommunityEnabled() {
    return this.channelService.isRolePresent(UserRole.community);
  }

  /**
   * Used in the MoviePageResolver
   */
  asyncMovieRequest(slug: string | null) {
    lastValueFrom(this.getMovie(slug)).then(movie => {
      this.updateMovie(movie);
    });
  }

  // community movies query

  getCommunityMovies(
    type: PageType,
    filter: MovieListWrapper | PerformanceListWrapper | MovieStreamingListWrapper | MovieFestivalListWrapper,
    channelSettings?: ChannelSettings
  ): Observable<Movie[]> {
    if (this.isLocalEnv && this.placeholderData?.movies) {
      const placeholder = typeof this.placeholderData.movies === 'boolean' ? '1' : this.placeholderData.movies;

      console.warn('Using placeholder data for movies');
      return this.http
        .get(`https://cdnstatic.usheru.com/assets/placeholder-data/movies-${placeholder}.json`, {
          headers: this.usheruApi.getStandardHeader()
        })
        .pipe(map((vals: Movie[]) => vals.map(val => posGetMovieFromApi(val))));
    }

    const headers = this.userSig()?.username
      ? this.usheruApi.generateBearerHeader(this.authService.accessToken())
      : this.usheruApi.getStandardHeader();

    switch (type) {
      case 'in-cinemas': {
        const tmpFilterIC = filter as PerformanceListWrapper;
        if (
          (tmpFilterIC.cinema || (tmpFilterIC.latitude && tmpFilterIC.longitude)) &&
          !channelSettings?.disableShowtimes
        ) {
          if (channelSettings?.showtimeRadius) {
            (filter as PerformanceListWrapper).radius = channelSettings.showtimeRadius;
          }
          return this.http
            .get<Movie[]>(`${this.env.api?.url}/movies/${type}/`, {
              headers: headers,
              params: this.movieHttpParams(tmpFilterIC)
            })
            .pipe(map(vals => vals.map(val => posGetMovieFromApi(val))));
        } else {
          return of(<Movie[]>[]);
        }
      }
      case 'watch-at-home': {
        if (!channelSettings?.disableStreaming) {
          return this.http
            .get<Movie[]>(`${this.env.api?.url}/movies/${type}/`, {
              headers: headers,

              params: this.movieHttpParams(filter)
            })
            .pipe(map(vals => vals.map(val => posGetMovieFromApi(val))));
        } else {
          return of(<Movie[]>[]);
        }
      }
      case 'coming-soon': {
        return this.http
          .get<Movie[]>(`${this.env.api?.url}/movies/${type}/`, {
            headers: headers,
            params: this.movieHttpParams(filter)
          })
          .pipe(map(vals => vals.map(val => posGetMovieFromApi(val))));
      }
      case 'at-festivals':
        return this.http
          .get<Movie[]>(`${this.env.api?.url}/movies/${type}/`, {
            headers: headers,
            params: this.movieHttpParams(filter)
          })
          .pipe(map(vals => vals.map(val => posGetMovieFromApi(val))));
      case 'tag':
        return this.http
          .get<Movie[]>(`${this.env.api?.url}/movies/`, {
            headers: headers,
            params: this.movieHttpParams(filter)
          })
          .pipe(map(vals => vals.map(val => posGetMovieFromApi(val))));
      default:
        return this.http
          .get<Movie[]>(`${this.env.api?.url}/movies/`, {
            headers: headers,
            params: this.movieHttpParams(filter)
          })
          .pipe(map(vals => vals.map(val => posGetMovieFromApi(val))));
    }
  }

  // regular movies query

  getMovies(
    type: PageType,
    channelSettings: ChannelSettings | undefined,
    filter: MovieListWrapper | PerformanceListWrapper | MovieStreamingListWrapper | MovieFestivalListWrapper,
    country?: Country,
    language?: Language
  ): Observable<Movie[]> {
    // PLACEHOLDER /////
    if (this.isLocalEnv && this.placeholderData?.movies) {
      const placeholder = typeof this.placeholderData.movies === 'boolean' ? '1' : this.placeholderData.movies;
      console.warn('Using placeholder data for movies');
      return this.http
        .get(`https://cdnstatic.usheru.com/assets/placeholder-data/movies-${placeholder}.json`, {
          headers: this.usheruApi.getStandardHeader()
        })
        .pipe(map((vals: Movie[]) => vals.map(val => posGetMovieFromApi(val))));
    }

    ////////////////////

    const headers = this.userSig()?.username
      ? this.usheruApi.generateBearerHeader(this.authService.accessToken())
      : this.usheruApi.getStandardHeader();

    switch (type) {
      case 'in-cinemas': {
        const tmpFilterIC = filter as PerformanceListWrapper;
        if (
          (tmpFilterIC.cinema || (tmpFilterIC.latitude && tmpFilterIC.longitude)) &&
          !channelSettings?.disableShowtimes
        ) {
          if (channelSettings?.showtimeRadius) {
            (filter as PerformanceListWrapper).radius = channelSettings.showtimeRadius;
          }
          return this.http
            .get<Movie[]>(`${this.env.api?.url}/movies/${type}/`, {
              headers,
              params: this.movieHttpParams(tmpFilterIC, country, language)
            })
            .pipe(map((vals: Movie[]) => vals.map(val => posGetMovieFromApi(val))));
        } else {
          return of(<Movie[]>[]);
        }
      }
      case 'watch-at-home': {
        if (!channelSettings?.disableStreaming) {
          return this.http
            .get<Movie[]>(`${this.env.api?.url}/movies/${type}/`, {
              headers,
              params: this.movieHttpParams(filter, country, language)
            })
            .pipe(map(vals => vals.map(val => posGetMovieFromApi(val))));
        } else {
          return of(<Movie[]>[]);
        }
      }
      case 'coming-soon': {
        return this.http
          .get<Movie[]>(`${this.env.api?.url}/movies/${type}/`, {
            headers,
            params: this.movieHttpParams(filter, country, language)
          })
          .pipe(map(vals => vals.map(val => posGetMovieFromApi(val))));
      }
      case 'at-festivals':
        return this.http
          .get<Movie[]>(`${this.env.api?.url}/movies/${type}/`, {
            headers,
            params: this.movieHttpParams(filter, country, language)
          })
          .pipe(map(vals => vals.map(val => posGetMovieFromApi(val))));
      case 'tag':
        return this.http
          .get<Movie[]>(`${this.env.api?.url}/movies/`, {
            headers,
            params: this.movieHttpParams(filter, undefined, language)
          })
          .pipe(map(vals => vals.map(val => posGetMovieFromApi(val))));
      default:
        return this.http
          .get<Movie[]>(`${this.env.api?.url}/movies/`, {
            headers,
            params: this.movieHttpParams(filter, undefined, language)
          })
          .pipe(map(vals => vals.map(val => posGetMovieFromApi(val))));
    }
  }

  //// get tag types

  public getTagTypes(): Observable<TagType[]> {
    if (this.isLocalEnv && this.placeholderData?.tagsTypes) {
      const placeholder = typeof this.placeholderData.tagsTypes === 'boolean' ? '1' : this.placeholderData.tagsTypes;
      console.warn('Using placeholder data for tagTypes');
      return this.http
        .get(`https://cdnstatic.usheru.com/assets/placeholder-data/tagsTypes-${placeholder}.json`, {
          headers: this.usheruApi.getStandardHeader()
        })

        .pipe(map(val => val as TagType[]));
    }
    return this.http.get<TagType[]>(`${this.env.api?.url}/tags/types`, {
      headers: this.usheruApi.getStandardHeader()
    });
  }

  //// get tags

  public getTags(tagType?: string): Observable<Tag[]> {
    if (this.isLocalEnv && this.placeholderData?.tags) {
      const placeholder = typeof this.placeholderData.tags === 'boolean' ? '1' : this.placeholderData.tags;
      return this.http
        .get(`https://cdnstatic.usheru.com/assets/placeholder-data/tags-${placeholder}.json`, {
          headers: this.usheruApi.getStandardHeader()
        })
        .pipe(map(val => val as Tag[]));
    }
    let params = new HttpParams();
    if (tagType) params = params.set('type', tagType);

    const headers = this.userSig()?.username
      ? this.usheruApi.generateBearerHeader(this.authService.accessToken())
      : this.usheruApi.getStandardHeader();

    return this.http.get<Tag[]>(`${this.env.api?.url}/tags/?pageOffset=50`, {
      headers,
      params
    });
  }

  //// get tag

  public getTag(tag: string | number): Observable<Tag> {
    const headers = this.usheruApi.getStandardHeader();
    return this.http.get<Tag>(`${this.env.api?.url}/tags/${tag}?pageOffset=50`, {
      headers
    });
  }

  //// streaming platforms

  public getStreamingPlatforms(countryId: string): StreamingPlatform[] {
    if (countryId) {
      const streamingPlatforms = this.streamingPlatformsMapping[countryId];
      if (streamingPlatforms) {
        streamingPlatforms.sort((a, b) => {
          if (a.order != b.order) {
            return a.order - b.order;
          } else {
            return a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1;
          }
        });
        return [...streamingPlatforms];
      }
    }
    return [];
  }

  getCinema(slug: string): Observable<Cinema> {
    return this.http.get<Cinema>(`${this.env.api?.url}/cinemas/${slug}`, {
      headers: this.usheruApi.getStandardHeader()
    });
  }

  getCinemas(filter: PerformanceListWrapper): Observable<Cinema[]> {
    if (Object.keys(filter).length === 0) {
      return of(<Cinema[]>[]);
    }
    return this.http.get<Cinema[]>(`${this.env.api?.url}/cinemas/`, {
      headers: this.usheruApi.getStandardHeader(),
      params: this.usheruApi.filterToParams(filter)
    });
  }

  getCinemaChannel(cinema: Cinema): Observable<CinemaChannel> {
    return this.http.get<CinemaChannel>(`${this.env.api?.url}/cinemaChannel/${cinema.id}`, {
      headers: this.usheruApi.getStandardHeader()
    });
  }

  getPerformance(id: number): Observable<Performance> {
    if (this.isLocalEnv && this.placeholderData?.performancesGroup) {
      const placeholder =
        typeof this.placeholderData.performancesGroup === 'boolean' ? '1' : this.placeholderData.performancesGroup;
      console.warn('Using placeholder data for performance');
      return this.http
        .get(`https://cdnstatic.usheru.com/assets/placeholder-data/performance-${placeholder}.json`, {
          headers: this.usheruApi.getStandardHeader()
        })
        .pipe(map(val => val as Performance));
    }

    //

    return this.http.get<Performance>(`${this.env.api?.url}/showtimes/${id}`, {
      headers: this.usheruApi.getStandardHeader()
    });
  }

  getPerformances(filter: PerformanceListWrapper): Observable<PerformanceGroup[]> {
    return this.http

      .get<PerformanceGroup[]>(`${this.env.api?.url}/showtimes/`, {
        headers: this.usheruApi.getStandardHeader(),

        params: this.usheruApi.filterToParams(filter)
      })

      .pipe(
        map(val =>
          val.map(performanceGroup => {
            performanceGroup.movie = posGetMovieFromApi(performanceGroup.movie);

            return performanceGroup;
          })
        )
      );
  }

  getPerformancesGroup(filter: PerformanceListWrapper): Observable<PerformanceGroup[]> {
    // id the response the ids, localTimes and attributeSlugs are string, we need to parse them.
    const pipeMapParseIdsDatesAndAttributes = (val: PerformanceGroup[]) =>
      val.map(performanceGroup => {
        if (performanceGroup.ids) {
          const ids = JSON.parse(`[${performanceGroup.ids as unknown as string}]`);
          performanceGroup.parsedIds = ids;
        }

        if (performanceGroup.localTimes) {
          const localTimes = (performanceGroup.localTimes as unknown as string).split(',').map(localTime => {
            const arr = localTime.split(' ');
            const stringDate = arr[0].split('-').reverse().join('/');
            const hour = arr[1].replace('AM', ' AM').replace('PM', ' PM');
            return new Date(`${stringDate} ${hour}`);
          });

          performanceGroup.parsedLocalTimes = localTimes;
        }

        if (performanceGroup.attributeSlugs) {
          const attributesGroup = (performanceGroup.attributeSlugs as unknown as string).split('],[');
          const matrixAttributes = attributesGroup.map(item => item.replace(/\[|\]/g, '').split(','));
          performanceGroup.parsedAttributeSlugs = matrixAttributes;
        }
        return performanceGroup;
      });

    const {
      /*performancesGroupFilter, performanceGroupFilterByMovie,*/ performancesAttributeFilter,
      performancesAttributeFilterByMovie
    } = this.env?.showtimeStreamingConfig || {};

    //
    // const filterPerformances = performanceGroupFilterByMovie ? performanceGroupFilterByMovie[`${filter?.movie[0]}`] : performancesGroupFilter;
    // const pipeMapFilter = (val: PerformanceGroup[]) => (filterPerformances ? val.filter(filterPerformances) : val);
    //
    const validAttribute = performancesAttributeFilterByMovie
      ? performancesAttributeFilterByMovie[`${filter?.movie[0]}`]
      : performancesAttributeFilter;
    const pipePerformanceAttributeFilter = (val: PerformanceGroup[]) => {
      if (!validAttribute) return val;

      return val.reduce((acc, performanceGroup) => {
        const validIdWithHisAttributes: { id: number; localTime: Date; attr: string[] }[] = [];
        if (validIdWithHisAttributes.length === 0) return acc;
        performanceGroup.parsedIds = validIdWithHisAttributes.map(val => val.id);
        performanceGroup.parsedLocalTimes = validIdWithHisAttributes.map(val => val.localTime);
        performanceGroup.parsedAttributeSlugs = validIdWithHisAttributes.map(val => val.attr);
        acc.push(performanceGroup);
        return acc;
      }, [] as PerformanceGroup[]);
    };

    if (this.isLocalEnv && this.placeholderData?.performancesGroup) {
      const placeholder =
        typeof this.placeholderData.performancesGroup === 'boolean' ? '1' : this.placeholderData.performancesGroup;
      console.warn('Using placeholder data for performancesGroup');
      return this.http
        .get(`https://cdnstatic.usheru.com/assets/placeholder-data/performancesGroup-${placeholder}.json`, {
          headers: this.usheruApi.getStandardHeader()
        })

        .pipe(
          map(val => val as PerformanceGroup[]),
          map(pipeMapParseIdsDatesAndAttributes),
          /*map(pipeMapFilter),*/
          map(pipePerformanceAttributeFilter),
          map(val =>
            val.map(performanceGroup => {
              performanceGroup.movie = posGetMovieFromApi(performanceGroup.movie);
              return performanceGroup;
            })
          )
        );
    }

    //
    return this.http
      .get<PerformanceGroup[]>(`${this.env.api?.url}/showtimes/group/`, {
        headers: this.usheruApi.getStandardHeader(),
        params: this.usheruApi.filterToParams(filter)
      })

      .pipe(
        map(pipeMapParseIdsDatesAndAttributes),
        map(pipePerformanceAttributeFilter),
        map(val =>
          val.map(performanceGroup => {
            performanceGroup.movie = posGetMovieFromApi(performanceGroup.movie);
            return performanceGroup;
          })
        )
      );
  }

  getNearestCities(filter: NearestCitiesFilter): Observable<NearCity[]> {
    if (this.isLocalEnv && this.placeholderData?.nearestCities) {
      const placeholder =
        typeof this.placeholderData.performancesGroup === 'boolean' ? '1' : this.placeholderData.performancesGroup;
      console.warn('Using placeholder data for nearestCities');

      return this.http
        .get(`https://cdnstatic.usheru.com/assets/placeholder-data/nearest-cities-${placeholder}.json`)
        .pipe(map(val => val as NearCity[]));
    }

    return this.http.get<NearCity[]>(`${this.env.api?.url}/movies/in-cinemas/nearest-cities/`, {
      headers: this.usheruApi.getStandardHeader(),
      params: this.usheruApi.filterToParams(filter)
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private movieHttpParams(filter: any, country?: Country, language?: Language) {
    let params = this.usheruApi.filterToParams(filter);
    if (country) {
      params = params.set('locCountry', country.slug);
    }
    if (language) {
      params = params.set('trLanguage', language.slug || '');
    }
    return params;
  }

  getAlumniList(filter: StarListWrapper): Observable<Star[]> {
    return this.http.get<Star[]>(`${this.env.api?.url}/stars/alumnis`, {
      headers: this.usheruApi.getStandardHeader(),
      params: this.usheruApi.filterToParams(filter)
    });
  }

  getStar(idSlug: string | number): Observable<Star> {
    return this.http.get<Star>(`${this.env.api?.url}/stars/${idSlug}`, {
      headers: this.usheruApi.getStandardHeader()
    });
  }

  getStarList(filter: StarListWrapper): Observable<Star[]> {
    return this.http.get<Star[]>(`${this.env.api?.url}/stars/`, {
      headers: this.usheruApi.getStandardHeader(),
      params: this.usheruApi.filterToParams(filter)
    });
  }

  getRightAvailabilities(filter: RightAvailabilitiesFiltersWrapper): Observable<RightAvailability[]> {
    return this.http.get<RightAvailability[]>(`${this.env.api?.url}/right-availabilities/`, {
      headers: this.usheruApi.getStandardHeader(),
      params: this.usheruApi.filterToParams(filter)
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  postAvailabilityInterest(availabilityInterest: any): Observable<ConsumerLead> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    return this.http.post<any>(`${this.env.api?.url}/right-availabilities-interest/`, availabilityInterest, {
      headers: this.usheruApi.getStandardPostHeader()
    });
  }

  getStreamingLinks(filter: MovieStreamingListWrapper): Observable<MovieStreaming[]> {
    if (this.isLocalEnv && this.placeholderData?.streamingLinks) {
      const placeholder =
        typeof this.placeholderData.streamingLinks === 'boolean' ? '1' : this.placeholderData.streamingLinks;
      console.warn('Using placeholder data for streamingLinks');
      return this.http
        .get<MovieStreaming[]>(
          `https://cdnstatic.usheru.com/assets/placeholder-data/streamingLinks-${placeholder}.json`,
          {
            headers: this.usheruApi.getStandardHeader()
          }
        )
        .pipe(map(this.copyFirstCountryToRestOfArray));
    }

    return this.http
      .get<MovieStreaming[]>(`${this.env.api?.url}/streamingLinks/`, {
        headers: this.usheruApi.getStandardHeader(),
        params: this.usheruApi.filterToParams(filter)
      })
      .pipe(map(this.copyFirstCountryToRestOfArray));
  }

  private copyFirstCountryToRestOfArray(streamingLinks: MovieStreaming[]): MovieStreaming[] {
    if (!streamingLinks?.length) return [];
    const country = streamingLinks[0].country;
    streamingLinks.forEach(sl => (sl.country = country));
    return streamingLinks;
  }

  public getStreamingPlatformsApi(filter: StreamingListWrapper): Observable<StreamingPlatform[]> {
    if (Object.keys(filter)?.length === 0 || filter?.country?.length === 0) {
      return of(<StreamingPlatform[]>[]);
    }

    return this.http.get<StreamingPlatform[]>(`${this.env.api?.url}/streamingPlatforms/available/`, {
      headers: this.usheruApi.getStandardHeader(),
      params: this.usheruApi.filterToParams(filter)
    });
  }

  getFestivals(keyword: string): Observable<Festival[]> {
    return this.http.get<Festival[]>(`${this.env.api?.url}/movies/festivals/?keyword=${keyword}`, {
      headers: this.usheruApi.getStandardHeader()
    });
  }

  getFestivalTypes(): Observable<FestivalType[]> {
    return this.http.get<FestivalType[]>(`${this.env.api?.url}/movies/festivalTypes/`, {
      headers: this.usheruApi.getStandardHeader()
    });
  }

  getFormattedCinemasReleaseDate(movie: Movie): string {
    if (movie.locReleaseDate) {
      const now = new Date().getTime();
      const correctLocReleaseDateFormat = `${movie.locReleaseDate.slice(6, 10)}-${movie.locReleaseDate.slice(3, 5)}-${movie.locReleaseDate.slice(0, 2)}`;
      const locReleaseDate = new Date(correctLocReleaseDateFormat);
      const locReleaseDateEpoch = locReleaseDate.getTime();
      if (locReleaseDateEpoch > now) {
        const dateOptions: Intl.DateTimeFormatOptions = {
          day: 'numeric',
          month: 'long'
        };

        if (this.isFormattedCinemasLong()) dateOptions['year'] = 'numeric';
        const date = new Date(locReleaseDateEpoch).toLocaleDateString(
          this.translationsService.currentLanguage().code,
          dateOptions
        );

        return this.translationsService.translate('movie.inCinemasDate').replace('_date_', date);
      } else {
        return this.translationsService.translate('movie.inCinemasNow');
      }
    }
    return this.translationsService.translate('movie.inCinemasNow');
  }

  private isFormattedCinemasLong(): boolean {
    return (
      this.env.movieCardParams?.inCinemasDateFormat === undefined ||
      this.env.movieCardParams?.inCinemasDateFormat === 'long'
    );
  }

  public getMoviesLastType(): string {
    return this.moviesLastType;
  }

  public setMoviesLastType(val: string): void {
    this.moviesLastType = val;
  }

  public setMoviesLastPage(val: number) {
    this.moviesLastPage = val;
  }

  public getMoviesLastPage() {
    return this.moviesLastPage;
  }
}
